

//base colors
$white: #fff;
$red-fade:#f45d59;
$n-green: #2ecc71;
$yello-n: #f1c40f;
$purple:#7986cb;
$blue:#20a8d8;
$black1:#333;
$grey2:#787878;

// color maps
$colors-asc: (
  "red-fade":#f45d59,
  "n-green": #2ecc71,
  "yello-n": #f1c40f,
  "purple":#7986cb,
  "grey":#f5f5f5,
  "black":#333,
  "grey2":#787878,
  "grey3":#f8fafc,
  "blue":#20a8d8
) !default;

$gradient-asc:(
  "aura": #000,
  "sun": linear-gradient(135deg, #ffce2b 0%,#feba31 27%,#fea436 65%,#fe913e 100%),
  "peach": linear-gradient(135deg, #ff875e 0%,#ff816b 27%,#ff7885 64%,#fe6ba3 100%),
  "strom": linear-gradient(135deg, #ed70ea 0%,#bc6af0 32%,#9e66f5 72%,#8463f9 100%)
) !default;

$padding-0:'0';
$border-0:'0px';
ul { padding-left: 0px;  list-style: none;  margin-bottom: 0px;}
ul.filter-block li {display: inline-block;}
.for-hide-fields.wd-1 .dropdown-menu {width: 650px !important;}
.for-hide-fields.wd-2 .dropdown-menu {width: 364px !important;}
.pick-another  .Select {position: relative;width: 152px !important;}
i.fa.fa-trash:hover {color:$red-fade;}
.pull-left{float: left;}
.pull-right{float: right;}

// color class defination "background color with white text"

@each $colors-asc, $color in $colors-asc
{
    .asc-#{$colors-asc} {
        background-color:$color;
        
    }
}
@each $colors-asc, $color-text in $colors-asc
{
    .asc-text-#{$colors-asc} {     
        color:$color-text;
    }
}
@each $gradient-as, $gradients in $gradient-asc
{
    .asc-#{$gradient-as} {
        background:$gradients;
        color:$white;
    }
}
.search-drop input {  border: 0px;  border-bottom: 1px solid #e2e2e2;  border-radius: 0px;}
.dropdown-menu.show { font-size: 12px !important;  padding: 0 13px;}
.b-left.pull-left span {vertical-align: middle;}
.filter-btn.clear-fix button {  font-size: 11px; background: #f5f5f5;  border: 1px solid #e2e2e2;  border-radius: 2px;}
.form-control:focus {  border-color: none; outline: 0;  box-shadow: none;}
span.for-label-text{ display: inline-block;}
span.for-label-drop, span.for-input-text {  display: inline-block;min-width: 128px;}
span.for-division{display: inline-block;
    width: 95px;
}

//common for select
.Select-placeholder, .Select--single > .Select-control .Select-value {  bottom: 0;   color: $black1 !important;}
.Select-control {  border: 0px !important;}
//additional custom styling


//styling for table
 .react-grid-HeaderCell {  background: $purple!important;  color: $white;}
 .react-grid-Main { padding: 0px;  outline: 0;  clear: both;}


 //card common
 .my-card{
    .card-header{
      h6 {font-size: 13px;}
    }
    small.muted{font-size: 12px; color:$grey2; display: inline-block;}
        p.card-text { font-size: 14px; color: $black1;}
        .hover-enable .qa-toggle:hover .collapse {
            display: block !important;
            animation: slide-down .3s ease-out;
            cursor: pointer;       ;
        }
        .tags p, .task p {
            color: #787878 !important;font-size: 13px;
         }
         .badge{color:$white;}
         .status-detail{ font-size: 12px;}
         .img-card img {
            width: 100%;
            height: auto;
        }
      
        
        .additional-info.for-tag-reverse .badge { font-weight: 500; color:$white !important; }
    }


 // just for demo not need to use.

 .hover-enable .qa-toggle:hover .collapse { display: block !important; animation: slide-down .3s ease-out;  cursor: pointer; }
 .img-disable .img-card{ display: none;}


 @keyframes slide-down {
    0% { opacity: 0; -webkit-transform: translateY(-40%); }   
  100% { opacity: 1; -webkit-transform: translateY(0); }
}
@-moz-keyframes slide-down {
    0% { opacity: 0; -moz-transform: translateY(-40%); }   
  100% { opacity: 1; -moz-transform: translateY(0); }
}
.dropdown-menu.show {
    min-width: 230px;
}
.react-contextmenu {
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 16px;
    color: #373a3c;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    outline: none;
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease !important;
  }
  
  .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
  }
  
  .react-contextmenu-item {
    padding: 3px 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #373a3c;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
    cursor: pointer;
  }
  
  .react-contextmenu-item.react-contextmenu-item--active,
  .react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
  }
  
  .react-contextmenu-item.react-contextmenu-item--disabled,
  .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    color: #878a8c;
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }
  
  .react-contextmenu-item--divider {
    margin-bottom: 3px;
    padding: 2px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
  }
  .react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }
  
  .react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
  }
  
  .react-contextmenu-item.react-contextmenu-submenu
    > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
  }
  .filter-view .fixed-bottom{
    position: relative;
  }
  .ReactVirtualized__Table__row > div{
    //display: block!important; 
   // display: inline-block!important;
  }
  .form-check-inline .form-check-input {
    width: 20px;
}
.child-grid{
  height: 90vh!important;
  overflow: scroll;
}
 
// filter css
/* css update for new module */
.filter-block .dropdown-menu.show {min-width: 296px;}
.filter-block label {display: inline-block;}
.filter-block .search-drop {margin-top: 10px;margin-bottom: 5px;}
.filter-block span.for-icon-text {margin-top: -7px !important;display: inline-block;}
ul.fields-list.mt-1 li {display: block;}
.for-hide-fields.wd-1 .dropdown-menu {width: 650px !important;padding: 12px 12px;}
span.for-label-text.pt-3 { min-width: 113px;}
.filter-btn.clear-fix button, .filter-block button.pull-right.btn.btn-secondary {font-size: 11px !important;background: #f5f5f5 !important;border: 1px solid #e2e2e2 !important;
  border-radius: 3px !important;padding: 7px 8px !important;color:#000;}
.filter-btn button:hover, .filter-block button.pull-right.btn.btn-secondary:hover{background: #f45d59 !important;color: #fff;}
.for-hide-fields.wd-2 .dropdown-menu.show {width: 422px !important;}
.for-hide-fields.wd-2 span.for-label-text.pt-3 {min-width: 65px;}
.for-hide-fields.wd-2 span.for-label-drop, .for-hide-fields.wd-2 span.for-input-text {display: inline-block;min-width: 153px;}
ul.fields-list.pb-1 li {display: block;}
.asc-white.row {margin-top: 10px !important; margin-bottom: 10px !important;}
.filter-block  button.dropdown-toggle.btn { font-size: 14px;}
.for-hide-fields  i.fa.fa-eye-slash {font-size: 15px;}
.filter-split {text-align: right;}
.filter-split li { margin-left: 8px;}
.filter-split button.dwnld {min-width: 133px;}
.filter-split button .fa {font-size: 16px;padding-right: 5px;}
.custom-form-grp.row {margin-bottom: 20px;}
.filter-split button.btn.icon-btn-link {color: #c4c4c4;}
.filter-split button.btn.icon-btn-link:hover{color: #f45d59 !important;}


// react grid css update
.react-grid-checkbox-container [type="checkbox"]:not(:checked) + label:before, .react-grid-checkbox-container [type="checkbox"]:checked + label:before {top: -20px;}
.react-grid-Cell__value .react-grid-checkbox-container [type="checkbox"]:not(:checked) + label:before, .react-grid-Cell__value .react-grid-checkbox-container [type="checkbox"]:checked + label:before {top: -8px;}
.react-grid-Container [type="checkbox"]:not(:checked) + label:after, .react-grid-Container [type="checkbox"]:checked + label:after {content: '\2713 ';position: absolute;top: -4px !important;font-weight: 900;
  font-size: 15px;}
.react-grid-Container .react-grid-checkbox:checked + .react-grid-checkbox-label:before {background: #fff;box-shadow: none;}
.form-check-inline .form-check-input{position: relative !important}
.ReactVirtualized__Grid__innerScrollContainer {background: #fff !important;}
.ReactVirtualized__Table__headerRow {background: #7986cb !important;color: #fff;justify-content: space-between;height: 50px !important;border-top-left-radius: 5px !important;border-top-right-radius: 5px;}
.v-grid.col-sm-12 {margin-top: 15px;}
.ReactVirtualized__Table__row {border-bottom: 1px solid #e2e2e2;justify-content: space-between;}
.ReactVirtualized__Table__row [type="checkbox"]:not(:checked), .ReactVirtualized__Table__row [type="checkbox"]:checked {position: relative !important;left: 6px!important;top: 3px;}


.v-grid button.btn.btn-link {padding-left: 0;}
  .ReactVirtualized__Table__rowColumn {word-break: break-all !important;white-space: normal;flex-wrap: wrap !important;display: block;overflow: visible !important;overflow-wrap: break-word !important;}
  .ReactVirtualized__Table__rowColumn {
    text-overflow: ellipsis;
    white-space: initial !important;
}
ul.extra-list-item {height: 44px;overflow: auto;cursor: all-scroll;}
.child-grid {

  padding-top: 29px;
}
.ReactVirtualized__Grid.ReactVirtualized__Table__Grid:focus {
  box-shadow: none !important;
  border: none !important;
  outline: none;
}
.ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0;
  text-align: center;
}
.g-plus{
  background: rgb(121, 134, 203) !important;
  color: #fff;
  border-radius: 3px;
  width: 17px;
  display: inline-block;
  height: 19px;
  font-size: 21px;
  line-height: 19px;
}
.section-frame .tckt-edit-btn {
  float: right;
}
.ReactVirtualized__Table__rowColumn img {
  width: 90px !important;
}
@media all and (max-width:1440px) {
  .table-responsive button.btn.btn-link {
    text-align: left !important;
  }
}