
.attribute-select .rw-dropdown-list.rw-widget {
    left: -6px;
}
label.col-form-label.attribute-label {
    margin-left: 16px;
}
.for-add-field.btn-add-attr {
    left: 0px;
    top: 0px;
}
span.attr-badge.badge.badge-primary {
    margin-right: 10px;
    padding: 10px;
    font-size: 12px;
}
span.attr-badge.badge i.fa.fa-times {
    margin-left: 10px;
    cursor: pointer;
}
span.attr-badge.badge i.fa.fa-times:hover {
    cursor: pointer;
    font-size: 17px;
    color: red;
}
.col-sm-5.attribute-text input {
    margin-left: -5px;
}
.perPageFilter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.pageSelect{
    margin: 0 5px;
}
.pageSelect .css-bg1rzq-control{
    min-height: 20px!important;
}

.pageSelect .css-16pqwjk-indicatorContainer{
    padding: 2px;
}
.pageSelect svg{
    height: 15px;
    width: 15px;
}

.perPageFilter .css-1hwfws3 {
    height: 25px !important;
    min-width: 29px !important;
    padding-left: 5px !important;
}

.pageSelect svg{
    height: 15px;
    width: 15px;
}
.pageSelect .css-1szy77t-control{
    min-height: 25px!important;
}
.workingHours{border:thin solid #ccc;border-radius:10px; padding:10px; color:#787878}
.inputline{border:unset!important; border-bottom: thin solid #eaeaea;}

.css-1q6aiye{
  margin: -36px 10px !important;
  width: 98% !important;
 }


/*!
 * react-datetime css
 */

 .rdt {
    position: relative;
  }
  .rdtPicker {
    display: none;
    position: absolute;
    min-width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    border: 1px solid #f9f9f9;
  }
  .rdtOpen .rdtPicker {
    display: block;
  }
  .rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
  }
  
  .rdtPicker .rdtTimeToggle {
    text-align: center;
  }
  
  .rdtPicker table {
    width: 100%;
    margin: 0;
  }
  .rdtPicker td,
  .rdtPicker th {
    text-align: center;
    height: 28px;
  }
  .rdtPicker td {
    cursor: pointer;
  }
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
  }
  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: #999999;
  }
  .rdtPicker td.rdtToday {
    position: relative;
  }
  .rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #428bca;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
  }
  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  
  .rdtPicker td span.rdtOld {
    color: #999999;
  }
  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  .rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
  }
  .rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
  }
  .rdtPicker th.rdtSwitch {
    width: 100px;
  }
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
  }
  
  .rdtPrev span,
  .rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
  }
  
  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  .rdtPicker thead tr:first-of-type th {
    cursor: pointer;
  }
  .rdtPicker thead tr:first-of-type th:hover {
    background: #eeeeee;
  }
  
  .rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
  }
  
  .rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
  }
  .rdtPicker button:hover {
    background-color: #eee;
  }
  
  .rdtPicker thead button {
    width: 100%;
    height: 100%;
  }
  
  td.rdtMonth,
  td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
  }
  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: #eee;
  }
  
  .rdtCounters {
    display: inline-block;
  }
  
  .rdtCounters > div {
    float: left;
  }
  
  .rdtCounter {
    height: 100px;
  }
  
  .rdtCounter {
    width: 40px;
  }
  
  .rdtCounterSeparator {
    line-height: 100px;
  }
  
  .rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;
  
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
  }
  .rdtCounter .rdtBtn:hover {
    background: #eee;
  }
  .rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
  }
  
  .rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
  }
  
  .rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
  }
  
  .rdtTime td {
    cursor: default;
  }

  a.nav-link.global-setting{padding-left: 24px!important;}
  /* .global-setting ul.nav.nav-list-items.nested-child-last.show-panel {
    margin-left: 32px!important;
} */

.sidebar .nav .nav-item .global-setting  ul {padding-left: 2.4rem!important;}
.sidebar-fixed .sidebar .sidebar-nav{
  overflow-y: auto!important;
  overflow-x: hidden!important;
}
.notification-card-div{
  font-family: 'Inter','sans-serif' !important;
  }
  body[data-scroll-locked]::-webkit-scrollbar{
  width: 0px !important;
  }
  .divScroll{
  overflow-y: hidden !important;
  }
  .divScroll:hover{
  overflow-y: scroll !important;
  }
  .date-text,.subject-text{
   color:#1A202C !important;
  }
  .notification-subject-text{
  color:#718096 !important;
  }
  .chakra-tabs .custom-tabs button{
  font-size: 12px !important;
  padding: 8px 16px !important;
  margin: 0  !important;
  border: 0 !important;
  box-shadow: none !important;
  }
  .chakra-tabs .custom-tabs button div{
  padding: 0 !important;
  }
  .chakra-tabs .custom-tabs button div p{
  line-height: 12px !important;
  padding: 0 !important;
  }
  .chakra-tabs .custom-tabs button span{
  line-height: inherit !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  }
  @media only screen and (min-width: 1360px){
  .notification-card-div{
    min-width: 672px !important;
  } 
  }
  .divScroll:hover {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, .2) rgba(0, 0, 0, .1);
  }
  .divScroll .chakra-accordion__panel .css-7wgtq8{
  min-height: 160px !important;
  }
  .notification-card-header-toggle span.chakra-switch__track.css-ddil6j {
  width: 22px !important;
  height: 12px !important;
  }
  .notification-card-header-toggle span.chakra-switch__thumb.css-7roig{
  width: 12px !important;
  height: 12px !important;
  }
  .notification-card-header-toggle label.chakra-switch.css-xymwb9{
  margin: 0 !important;
  }
  
  .combo-input input{
    border-bottom: none !important;
  }
  .combo-input.rw-state-focus input[type='text']:focus{
    border-bottom: none !important;
    box-shadow: none !important;
  }
  .combo-input.rw-state-focus>div{
    box-shadow: none !important;
    /* border: 1px solid #c2c2c2 !important; */
  }
  .table-responsive {
    scrollbar-width:thin;
    scrollbar-color: #888888 #f1f1f1;
  }